<template>
  <div>
    <div hidden v-if="roleName === 'SYSTEM_ADMIN' || roleName === 'SYSTEM_USER' || roleName === 'ORGANIZATION_ADMIN'">
      <v-row>
        <!--        TODO this is not a good approach to have dialogs inside the component-->
        <v-dialog v-model="dialogDelete" max-width="380px"  transition="dialog-top-transition">
          <v-card class="pa-7">
            <v-card-title class="d-flex justify-center align-center">
              <v-row  dense>
                <v-col class="py-0 mb-1" cols="12">
                <span class="icon-background-alert">
                  <v-icon dense color="main_red" size="30px" class="my-0">mdi-alert-outline</v-icon>
                </span>
                </v-col>
                <v-col cols="12">
                  <span>{{ $t('deleteQuestion') }}</span>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text class="text-center">{{ $t('questionTextForDelete') }}</v-card-text>
            <v-card-actions class="mb-2">
              <v-row dense>
                <v-col cols="12">
                  <v-btn block depressed color="red" class="text-color-white"  @click="deleteUserConfirm">{{ $t('deleteField') }}</v-btn>
                </v-col>
                <v-col cols="12">
                  <v-btn outlined depressed color="black" block @click="closeDeleteDialog">{{ $t("cancel") }}</v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </div>
    <v-data-table
        class="pa-1 pointer"
        :loading="loading"
        :headers="headers"
        :headers-length="headers.length"
        :no-data-text="$t('no-data-available')"
        :loading-text="$t('loading')"
        :items="filterBillings"
        sort-by="id"
        item-key="id"
        fixed-header
        dense
        :height="pageHeight(160)"
        :footer-props="footerProps"
        :items-per-page="-1"
    >
      <template v-slot:item="{ item }">
        <tr>
          <td class="text-left font-size12-5 font-weight-medium">
            {{ item.organizationName }}
          </td>
          <td class="text-left font-size12-5 font-weight-medium">
            {{ item.officeName }}
          </td>
          <td>
            <v-icon v-if="item.rpm99453Allowed" class="mdi mdi-checkbox-blank mdi-18px color-primary"></v-icon>
            <v-icon v-else class="mdi mdi-checkbox-blank-outline mdi-18px color-red color-primary"></v-icon>
          </td>
          <td>
            <v-icon v-if="item.rpm99454Allowed" class="mdi mdi-checkbox-blank mdi-18px color-primary"></v-icon>
            <v-icon v-else class="mdi mdi-checkbox-blank-outline mdi-18px color-red color-primary"></v-icon>
          </td>
          <td>
            <v-icon v-if="item.rpm99457Allowed" class="mdi mdi-checkbox-blank mdi-18px color-primary"></v-icon>
            <v-icon v-else class="mdi mdi-checkbox-blank-outline mdi-18px color-red color-primary"></v-icon>
          </td>
          <td>
            <v-icon v-if="item.rpm99458FirstAllowed" class="mdi mdi-checkbox-blank mdi-18px color-primary"></v-icon>
            <v-icon v-else class="mdi mdi-checkbox-blank-outline mdi-18px color-red color-primary"></v-icon>
          </td>
          <td>
            <v-icon v-if="item.rpm99458SecondAllowed" class="mdi mdi-checkbox-blank mdi-18px color-primary"></v-icon>
            <v-icon v-else class="mdi mdi-checkbox-blank-outline mdi-18px color-red color-primary"></v-icon>
          </td>
          <td>
            <v-icon v-if="item.rtm96127Allowed" class="mdi mdi-checkbox-blank mdi-18px color-primary"></v-icon>
            <v-icon v-else class="mdi mdi-checkbox-blank-outline mdi-18px color-red color-primary"></v-icon>
          </td>
          <td>
            <v-icon v-if="item.rtm98975Allowed" class="mdi mdi-checkbox-blank mdi-18px color-primary"></v-icon>
            <v-icon v-else class="mdi mdi-checkbox-blank-outline mdi-18px color-red color-primary"></v-icon>
          </td>
          <td>
            <v-icon v-if="item.rtm98977Allowed" class="mdi mdi-checkbox-blank mdi-18px color-primary"></v-icon>
            <v-icon v-else class="mdi mdi-checkbox-blank-outline mdi-18px color-red color-primary"></v-icon>
          </td>
          <td>
            <v-icon v-if="item.rtmG3002Allowed" class="mdi mdi-checkbox-blank mdi-18px color-primary"></v-icon>
            <v-icon v-else class="mdi mdi-checkbox-blank-outline mdi-18px color-red color-primary"></v-icon>
          </td>
          <td>
            <v-icon v-if="item.rtm98980Allowed" class="mdi mdi-checkbox-blank mdi-18px color-primary"></v-icon>
            <v-icon v-else class="mdi mdi-checkbox-blank-outline mdi-18px color-red color-primary"></v-icon>
          </td>
          <td>
            <v-icon v-if="item.rtm98981Allowed" class="mdi mdi-checkbox-blank mdi-18px color-primary"></v-icon>
            <v-icon v-else class="mdi mdi-checkbox-blank-outline mdi-18px color-red color-primary"></v-icon>
          </td>

          <td>
            <div class="d-flex align-center pl-3">
              <v-menu
                  left
                  bottom
                  class="py-0"
                  offset-y
                  transition="slide-x-transition"
              >
                <template v-slot:activator="{on, attrs}">
                  <v-btn
                      small
                      icon
                      color="black"
                      v-bind="attrs"
                      v-on="on"
                  >
                    <span class="mdi mdi-18px mdi-dots-horizontal"></span>
                  </v-btn>
                </template >

                <v-list class="px-0" dense >
                  <v-list-item class="py-0 my-0" link dense @click="handleActionEdit(item)">
                    <v-list-item-icon class="mx-1 ">
                      <v-icon class="icon-img mdi-18px" color="black">mdi-pencil</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content class="font-bold font-size14">{{$t("edit")}}</v-list-item-content>
                  </v-list-item>
                  <v-divider/>
                  <v-list-item v-if="roleName === 'SYSTEM_ADMIN' || roleName === 'SYSTEM_USER' || roleName === 'ORGANIZATION_ADMIN'" link dense @click="handleActionDelete(item)">
                    <v-list-item-icon class="mx-1 ">
                      <v-icon color="red">mdi-trash-can-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content class="font-bold font-size14" >{{ $t('delete') }}</v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import { showSuccessAlert, showErrorAlert } from '@/utils/utilities';
export default {
  data () {
    return {
      footerProps: { 'items-per-page-options': [-1, 5, 10, 50, 100] },
      loading: true,
      headerClass: 'color-header',
      selectedItem: {},
      dialogDelete: false,
    };
  },
  computed: {
    ...mapGetters({
      roleName: 'authentication/getRole',
      pageHeight: 'pageHeight',
      appBarSearch: 'filterbar/getSearch',
    }),
    ...mapState({
      billingsTemplates: (state) => state.billingsauthorization.billingsTemplates,
    }),
    filterBillings () {
      const searchTerm = this.appBarSearch ? this.appBarSearch.toLowerCase() : '';
      return this.billingsTemplates.filter(item => {
        const office = item.officeName && item.officeName ? item.officeName.toLowerCase() : '';
        return office.includes(searchTerm);
      });
    },
    headers () {
      var headers = [];
      headers = [
        {
          text: this.$t('medicalInstitution'),
          value: 'medicalInstitution',
          class: this.headerClass,
        },
        {
          text: this.$t('hospital'),
          value: 'hospital',
          class: this.headerClass,
        },
        {
          text: 'RPM 99453',
          value: 'rpm99453',
          class: this.headerClass,
          sortable: false,
        },
        {
          text: 'RPM 99454',
          value: 'rpm99454',
          class: this.headerClass,
          sortable: false,
        },
        {
          text: 'RPM 99457',
          value: 'rpm99457',
          class: this.headerClass,
          sortable: false,
        },
        {
          text: 'RPM 99458 1st',
          value: 'rpm99458First',
          class: this.headerClass,
          sortable: false,
        },
        {
          text: 'RPM 99458 2nd',
          value: 'rpm99458Second',
          class: this.headerClass,
          sortable: false,
        },
        {
          text: 'RTM 96127',
          value: 'rtm96127',
          class: this.headerClass,
          sortable: false,
        },
        {
          text: 'RTM 98975',
          value: 'rtm98975',
          class: this.headerClass,
          sortable: false,
        },
        {
          text: 'RTM 98977',
          value: 'rtm98977',
          class: this.headerClass,
          sortable: false,
        },
        {
          text: 'RTM G3002',
          value: 'rtmG3002',
          class: this.headerClass,
          sortable: false,
        },
        {
          text: 'RTM 98980',
          value: 'rtm98980',
          class: this.headerClass,
          sortable: false,
        },
        {
          text: 'RTM 98981',
          value: 'rtm98981',
          class: this.headerClass,
          sortable: false,
        },
        {
          text: this.$t('actions'),
          value: '',
          class: this.headerClass,
          align: '',
          sortable: false,
        },
      ];
      return headers;
    },
  },
  async mounted () {
    await this.getBillingAuthTemplate();
  },
  methods: {
    async getBillingAuthTemplate () {
      await this.$store.dispatch('billingsauthorization/billingauthorizationtemplates').then(() => {
        this.loading = false;
      });
    },
    handleActionEdit (item) {
      this.$store.commit('SET_UPDATE_DETAILS_DRAWER', item);
        this.$store.commit('SET_UPDATE_DETAILS_DRAWER', {
          updateDrawer: true,
          page2: 'billings authorization',
        });
        this.$store.dispatch('billingsauthorization/getbillingauthorizationtemplatesbyid', item.id);
    },
    async handleActionDelete (item) {
      this.dialogDelete = true;
      this.selectedItem = item;
    },
    async deleteUserConfirm () {
      await this.$store.dispatch('billingsauthorization/deletebillingauthorizationtemplates', this.selectedItem.id)
          .then(res => {
            if (res) {
              showSuccessAlert(this.$t('success-operation'));
            } else {
              showErrorAlert(this.$t('failed-operation'));
            }
            this.getBillingAuthTemplate();
            this.closeDeleteDialog();
          });
    },
    closeDeleteDialog () {
      this.dialogDelete = false;
      this.selectedItem = {};
    },
  },
  beforeDestroy () {
    this.$store.commit('SET_EDIT_DETAILS_DRAWER', false);
  },
};
</script>
<style>

</style>
